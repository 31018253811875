<template>
  <div class="my-password app-my-wrap">
    <div class="app-my-title">
      パスワード変更
    </div>
    <div class="app-my-content">
      <el-form ref="resetPasswordForm" :model="form" class="app-form" :rules="rules">
        <el-form-item label="現在のパスワード" prop="oldPassword">
          <el-input type="password" v-model="form.oldPassword" autocomplete="off"
            placeholder="現在のパスワード"
          ></el-input>
        </el-form-item>
        <el-form-item label="新しいパスワード" prop="newPassword">
          <el-input type="password" v-model="form.newPassword" autocomplete="off"
            placeholder="新しいパスワード"
          ></el-input>
        </el-form-item>
        <el-form-item label="新しいパスワード（再入力）" prop="newPasswordConfirm">
          <el-input type="password" v-model="form.newPasswordConfirm" autocomplete="off"
            placeholder="新しいパスワード（再入力）"
          ></el-input>
          <div style="color: #888">
            ※パスワードルールは半角英数字6桁以上。<br>
            ※パスワードは英字と数字をどちらも含む6文字以上の半角英数記号でご設定ください。
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="studypack-button-primary" type="primary" @click="onSubmit">変更</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import tools from '@/utils/tools';
import request from '@/utils/request';
import { Notification } from 'element-ui';

const { checkPassword } = tools;

export default {
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
        newPasswordConfirm: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { validator: this.newPasswordConfirm, trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    newPasswordConfirm(rule, value, callback) {
      if (value === '') {
        callback(new Error('パスワードをもう一度入力してください'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('二回の入力パスワードが一致しません!'));
      } else {
        callback();
      }
    },
    onSubmit() {
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          request('updatePassword', {
            password: this.form.oldPassword,
            newPassword: this.form.newPassword,
          }).then(() => {
            Notification({
              title: '成功',
              message: 'パスワードの変更が成功しました。',
              // duration: 0,
              offset: 75,
              type: 'success',
            });
            this.$router.push({
              path: '/login',
            }).catch(() => {});
          });
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
